import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import Box from '@material-ui/core/Box';
import AdSense from 'react-adsense';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Grid from '@material-ui/core/Grid';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ComputerIcon from '@material-ui/icons/Computer';
import DialpadIcon from '@material-ui/icons/Dialpad';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import ReactGA from "react-ga4";

const locationPath = "/";
const fullPath = "https://www.life-util.com" + locationPath;
const title = "Life-Util HOME"

const useStyles = makeStyles((theme) => ({
  releaseNotes: {
      height: '200px',
      overflowY: 'scroll',
    },
  root: {
    display: 'flex',
    '& > *': {
        margin: theme.spacing(1),
    },
  },
  list: {
    width: '100%',
    maxWidth: 460,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

function HomePage() {

  useEffect(() => {

    // Google Analytics measurement ID setting
    ReactGA.initialize("G-KT8FSRD8RJ");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: locationPath, title: title });

  }, []);

  const classes = useStyles();
  const xs = 12;
  const sm= 6;

  return (

      <div>
          <Helmet>
              <title>Life-Util</title>
              <meta name="description" content="役立つ各種ツールを公開しています。休業手当計算 複利計算 貯蓄率計算 FIRE計算 消費税 BMI計算 パスワード生成 など" />
              <link rel="alternate" href="https://www.life-util.com" hreflang="ja"/>
              <link rel="alternate" href="https://www.life-util.com/en/" hreflang="en" />
              <meta property="og:url" content={fullPath} />
              <meta property="og:type" content="website" />
              <meta property="og:title" content="Life-Util" />
              <meta property="og:description" content="役立つ各種ツールを公開しています。休業手当計算 複利計算 貯蓄率計算 FIRE計算 消費税 BMI計算 パスワード生成 など" />
              <meta property="og:site_name" content="Life-Util" />
          </Helmet>

          <div className={classes.root}>

          <Grid justify="center" container spacing={1}>
            <Grid item xs={xs} sm={sm}>
              <Paper variant="outlined">
                <div className={classes.list}>
                  <List component="nav" aria-label="ヘルスケアメニュー">
                    <ListItem>
                      <ListItemIcon>
                        <LocalHospitalIcon />
                      </ListItemIcon>
                      <ListItemText primary="ヘルスケア" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List component="nav" aria-label="BMI計算 BMR計算 平均余命計算">
                    <ListItemLink href="/calculator/healthcare/bmi-calculator">
                      <ListItemText primary="BMI計算" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/healthcare/basal-metabolic-rate-calculator">
                      <ListItemText primary="BMR計算" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/healthcare/expected-life-at-age-calculator">
                      <ListItemText primary="平均余命計算" />
                    </ListItemLink>
                  </List>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={xs} sm={sm}>
              <Paper variant="outlined">
                <div className={classes.list}>
                  <List component="nav" aria-label="カレンダーメニュー">
                    <ListItem>
                      <ListItemIcon>
                        <CalendarTodayIcon />
                      </ListItemIcon>
                      <ListItemText primary="カレンダー" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List component="nav" aria-label="日数計算 時間計算 (終了時間 - 開始時間) 時差計算 (タイムゾーン)">
                    <ListItemLink href="/calculator/calendar/date-calculator">
                      <ListItemText primary="日数計算" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/calendar/time-calculator">
                      <ListItemText primary="時間計算 (終了時間 - 開始時間)" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/calendar/time-difference-calculator">
                      <ListItemText primary="時差計算 (タイムゾーン)" />
                    </ListItemLink>
                  </List>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={xs} sm={sm}>
              <Paper variant="outlined">
                <div className={classes.list}>
                  <List component="nav" aria-label="ファイナンスメニュー">
                    <ListItem>
                      <ListItemIcon>
                        <AttachMoneyIcon />
                      </ListItemIcon>
                      <ListItemText primary="ファイナンス" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List component="nav" aria-label="ファイナンスツール">
                    <ListItemLink href="/calculator/finance/leave-allowance-monthly-salary-calculator">
                      <ListItemText primary="休業手当計算 (月給の場合)" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/finance/consumption-tax-calculator">
                      <ListItemText primary="消費税計算" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/finance/compound-interest-calculator">
                      <ListItemText primary="複利計算" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/finance/fire-simulator">
                      <ListItemText primary="FIREシミュレーター" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/finance/barista-fire-calculator">
                      <ListItemText primary="サイドFIREシミュレーター" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/finance/savings-rate-calculator">
                      <ListItemText primary="貯蓄率計算" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/finance/expected-asset-amount-calculator">
                      <ListItemText primary="期待資産額計算" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/finance/human-life-value-calculator">
                      <ListItemText primary="生命価値計算" />
                    </ListItemLink>
                  </List>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={xs} sm={sm}>
              <Paper variant="outlined">
                <div className={classes.list}>
                  <List component="nav" aria-label="単位換算メニュー">
                    <ListItem>
                      <ListItemIcon>
                        <DialpadIcon />
                      </ListItemIcon>
                      <ListItemText primary="単位換算" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List component="nav" aria-label="単位換算ツール">
                    <ListItemLink href="/calculator/converter/gram-to-ounce-converter">
                      <ListItemText primary="グラム⇔オンス換算" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/converter/inch-to-centimeter-converter">
                      <ListItemText primary="インチ⇔センチ換算" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/converter/mile-to-kilometer-converter">
                      <ListItemText primary="マイル⇔キロメートル換算" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/converter/meter-to-yard-converter">
                      <ListItemText primary="メートル⇔ヤード換算" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/converter/byte-converter">
                      <ListItemText primary="バイト換算" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/converter/square-metre-tsubo-tatami-converter">
                      <ListItemText primary="平米計算ツール" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/converter/torque-converter">
                      <ListItemText primary="トルク換算" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/converter/pressure-converter">
                      <ListItemText primary="圧力換算" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/converter/celsius-to-fahrenheit-converter">
                      <ListItemText primary="摂氏⇔華氏換算" />
                    </ListItemLink>
                    <ListItemLink href="/calculator/converter/quart-to-litre-converter">
                      <ListItemText primary="クォート⇔リットル換算" />
                    </ListItemLink>
                  </List>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={xs} sm={sm}>
              <Paper variant="outlined">
                <div className={classes.list}>
                  <List component="nav" aria-label="ITツールメニュー">
                    <ListItem>
                      <ListItemIcon>
                        <ComputerIcon />
                      </ListItemIcon>
                      <ListItemText primary="ITツール" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List component="nav" aria-label="ITツール">
                    <ListItemLink href="/tools/password-generator">
                      <ListItemText primary="パスワード生成" />
                    </ListItemLink>
                    <ListItemLink href="/tools/qr-code-generator">
                      <ListItemText primary="QRコード作成" />
                    </ListItemLink>
                    <ListItemLink href="/tools/barcode-generator">
                      <ListItemText primary="バーコード作成" />
                    </ListItemLink>
                    <ListItemLink href="/tools/color-picker">
                      <ListItemText primary="カラーピッカー" />
                    </ListItemLink>
                    <ListItemLink href="/tools/sketch-canvas">
                      <ListItemText primary="お絵描きサイト" />
                    </ListItemLink>
                    <ListItemLink href="/tools/character-counter">
                      <ListItemText primary="文字数カウント" />
                    </ListItemLink>
                    <ListItemLink href="/tools/xml-formatter">
                      <ListItemText primary="XMLフォーマッター" />
                    </ListItemLink>
                    <ListItemLink href="/tools/json-formatter">
                      <ListItemText primary="JSONフォーマッター" />
                    </ListItemLink>
                    <ListItemLink href="/tools/aspect-ratio-calculator">
                      <ListItemText primary="アスペクト比計算ツール" />
                    </ListItemLink>
                  </List>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={xs} sm={sm}>
              <Paper variant="outlined">
                <div className={classes.list}>
                  <List component="nav" aria-label="数学メニュー">
                    <ListItem>
                      <ListItemIcon>
                        <ComputerIcon />
                      </ListItemIcon>
                      <ListItemText primary="数学" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List component="nav" aria-label="数学ツール">
                    <ListItemLink href="/calculator/math/percentage-calculator">
                      <ListItemText primary="パーセント計算" />
                    </ListItemLink>
                  </List>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={xs} sm={sm}>
              <Paper variant="outlined">
                <div className={classes.list}>
                  <List component="nav" aria-label="ゲームメニュー">
                    <ListItem>
                      <ListItemIcon>
                        <SportsEsportsIcon />
                      </ListItemIcon>
                      <ListItemText primary="ゲーム" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List component="nav" aria-label="ゲーム">
                    <ListItemLink href="/game/chessboard">
                      <ListItemText primary="チェスボード" />
                    </ListItemLink>
                  </List>
                </div>
              </Paper>
            </Grid>

            <Grid item xs={xs} sm={sm}>
              <Paper variant="outlined">
                <div className={classes.list}>
                  <List component="nav" aria-label="リリースノート">
                    <ListItem>
                      <ListItemIcon>
                        <EventNoteIcon />
                      </ListItemIcon>
                      <ListItemText primary="リリースノート" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List component="nav" aria-label="リリースノート">
                    <Box className={classes.releaseNotes} p={1} m={1} bgcolor="background.paper" textAlign="left">
                      <Typography variant="body2" component="h4" >2024/5/19 <Link href="/tools/aspect-ratio-calculator">アスペクト比計算ツール</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2024/3/18 対応ブラウザを更新</Typography>
                      <Typography variant="body2" component="h4" >2023/12/31 対応ブラウザを更新</Typography>
                      <Typography variant="body2" component="h4" >2023/12/10 対応ブラウザを更新</Typography>
                      <Typography variant="body2" component="h4" >2023/9/24 <Link href="/tools/json-formatter">JSONフォーマッター</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2023/9/23 <Link href="/tools/xml-formatter">XMLフォーマッター</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2023/7/15 <Link href="/calculator/finance/human-life-value-calculator">生命価値計算</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2023/7/1 対応ブラウザを更新</Typography>
                      <Typography variant="body2" component="h4" >2023/4/15 <Link href="/calculator/math/percentage-calculator">パーセント計算</Link>に帯グラフを追加</Typography>
                      <Typography variant="body2" component="h4" >2023/3/19 対応ブラウザを更新</Typography>
                      <Typography variant="body2" component="h4" >2023/3/3 対応ブラウザを更新</Typography>
                      <Typography variant="body2" component="h4" >2023/1/28 <Link href="/game/chessboard">チェスボード</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2023/1/28 <Link href="/calculator/converter/pressure-converter">圧力換算</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2023/1/26 <Link href="/calculator/converter/meter-to-yard-converter">メートル⇔ヤード換算</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2023/1/26 <Link href="/tools/sketch-canvas">ブラウザお絵かきボード</Link>レイアウト調整</Typography>
                      <Typography variant="body2" component="h4" >2022/9/24 <Link href="/tools/sketch-canvas">ブラウザお絵かきボード</Link>にペンカラーパレットを追加</Typography>
                      <Typography variant="body2" component="h4" >2022/8/31 <Link href="/tools/sketch-canvas">ブラウザお絵かきボード</Link>のレイアウトを変更</Typography>
                      <Typography variant="body2" component="h4" >2022/8/14 <Link href="/tools/character-counter">文字数カウント</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2022/8/9 <Link href="/calculator/converter/quart-to-litre-converter">クォート⇔リットル換算</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2022/6/25 トページレイアウト改善</Typography>
                      <Typography variant="body2" component="h4" >2022/5/10 トップページにメニューを追加</Typography>
                      <Typography variant="body2" component="h4" >2022/4/25 サイト名を Life-Util (読み方はライフ・ユーティル)に変更</Typography>
                      <Typography variant="body2" component="h4" >2022/4/7 <Link href="/calculator/calendar/time-difference-calculator">時差計算 (タイムゾーン)</Link>を更新</Typography>
                      <Typography variant="body2" component="h4" >2022/3/9 <Link href="/calculator/converter/celsius-to-fahrenheit-converter">摂氏⇔華氏換算ツール</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2022/2/25 <Link href="/tools/sketch-canvas">お絵かきボード</Link>を更新</Typography>
                      <Typography variant="body2" component="h4" >2022/2/14 <Link href="/tools/sketch-canvas">スケッチボード</Link>を更新</Typography>
                      <Typography variant="body2" component="h4" >2022/2/9 <Link href="/calculator/healthcare/basal-metabolic-rate-calculator">基礎代謝量計算(BMR計算)</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2022/1/6 <Link href="/tools/password-generator">パスワード生成(パスワード作成)</Link>の機能改善</Typography>
                      <Typography variant="body2" component="h4" >2021/12/25 <Link href="/tools/sketch-canvas">スケッチキャンバス</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/12/22 <Link href="/tools/password-generator">パスワード生成(パスワード作成)</Link>の機能改善</Typography>
                      <Typography variant="body2" component="h4" >2021/10/13 <Link href="/calculator/converter/torque-converter">トルク換算</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/10/7 <Link href="/calculator/math/percentage-calculator">パーセント計算ツール</Link>に機能追加</Typography>
                      <Typography variant="body2" component="h4" >2021/9/26 <Link href="/tools/color-picker">カラーピッカー</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/9/26 <Link href="/calculator/converter/square-metre-tsubo-tatami-converter">平米計算ツール</Link>のレイアウトを改善</Typography>
                      <Typography variant="body2" component="h4" >2021/9/6 <Link href="/privacy-policy">プライバシーポリシー・免責事項</Link>を変更</Typography>
                      <Typography variant="body2" component="h4" >2021/9/3 <Link href="/calculator/math/percentage-calculator">パーセント計算ツール</Link>のレイアウト修正</Typography>
                      <Typography variant="body2" component="h4" >2021/9/2 <Link href="/calculator/math/percentage-calculator">パーセント計算ツール</Link>に機能追加</Typography>
                      <Typography variant="body2" component="h4" >2021/8/4 <Link href="/calculator/converter/byte-converter">バイト換算</Link>の画面レイアウト改善</Typography>
                      <Typography variant="body2" component="h4" >2021/7/26 <Link href="/calculator/finance/fire-simulator">FIREシミュレーター</Link>を更新</Typography>
                      <Typography variant="body2" component="h4" >2021/7/23 <Link href="/calculator/finance/barista-fire-calculator">サイドFIREシミュレーター</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/7/14 <Link href="/tools/password-generator">パスワード生成(パスワード作成)</Link>のUI改善</Typography>
                      <Typography variant="body2" component="h4" >2021/7/9 <Link href="/calculator/finance/fire-simulator">FIREシミュレーター</Link>を更新</Typography>
                      <Typography variant="body2" component="h4" >2021/6/13 <Link href="/calculator/math/percentage-calculator">割合計算 (パーセント計算)</Link>を更新</Typography>
                      <Typography variant="body2" component="h4" >2021/6/6 <Link href="/contact">お問い合わせフォーム</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/6/5 <Link href="/calculator/finance/fire-simulator">FIREシミュレーター</Link>を更新</Typography>
                      <Typography variant="body2" component="h4" >2021/6/3 <Link href="/calculator/math/percentage-calculator">割合計算(パーセント計算)ツールと計算方法</Link>を更新</Typography>
                      <Typography variant="body2" component="h4" >2021/6/1 <Link href="/calculator/healthcare/expected-life-at-age-calculator">平均余命計算</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/5/28 <Link href="/tools/password-generator">パスワード生成(パスワード作成)</Link>を更新</Typography>
                      <Typography variant="body2" component="h4" >2021/3/22 <Link href="/calculator/finance/expected-asset-amount-calculator">期待資産額計算</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/3/19 <Link href="/calculator/calendar/time-difference-calculator">時差計算 (タイムゾーン)</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/3/8 <Link href="/tools/barcode-generator">バーコード作成</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/3/4 <Link href="/calculator/converter/mile-to-kilometer-converter">マイル⇔キロメートル換算</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/2/28 <Link href="/calculator/math/percentage-calculator">割合計算 (パーセント計算)</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/2/27 <Link href="/calculator/converter/inch-to-centimeter-converter">インチ⇔センチ換算</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/2/24 <Link href="/tools/qr-code-generator">QRコード作成</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/2/22 <Link href="/calculator/finance/fire-simulator">FIREシミュレーター</Link>を更新</Typography>
                      <Typography variant="body2" component="h4" >2021/2/11 <Link href="/calculator/finance/leave-allowance-monthly-salary-calculator">休業手当計算</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/2/1 <Link href="/tools/password-generator">パスワード生成(パスワード作成)</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/1/26 <Link href="/calculator/converter/square-metre-tsubo-tatami-converter">平米/坪/畳 換算</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/1/24 <Link href="/calculator/converter/byte-converter">バイト換算</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/1/19 <Link href="/calculator/finance/fire-simulator">FIREシミュレーター</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/1/18 <Link href="/calculator/calendar/time-calculator">時間計算</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/1/15 <Link href="/calculator/finance/consumption-tax-calculator">消費税計算</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/1/14 <Link href="/calculator/calendar/date-calculator">日数計算</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/1/13 <Link href="/calculator/healthcare/bmi-calculator">BMI計算</Link>を追加</Typography>
                      <Typography variant="body2" component="h4" >2021/1/12 <Link href="/calculator/finance/compound-interest-calculator">複利計算</Link>を追加</Typography>
                    </Box>
          
                  </List>
                </div>
              </Paper>
            </Grid>

          </Grid>

        </div>
        
        <AdSense.Google
          client='ca-pub-6460385026549088'
          slot='3710473082'
          style={{ display: 'block' }}
          layout='in-article'
          format='fluid'
        />

      </div>

  );
}

export default HomePage;
